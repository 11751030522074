import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import ContentFloodHero from "../../../../V2/Heroes/Content/FloodHero"

import { getUrlFromStoryblokLink, linkIsNotNull } from "@utils/storyblok"

export default function StoryblokContentFloodHero({
  blok,
}: Storyblok.BlokProps<Storyblok.ContentFloodHero>) {
  return (
    <ContentFloodHero
      backgroundColor={blok.backgroundColor}
      primaryButtonBorderColor={blok.primaryCTAColor}
      superscriptText={blok.superscriptText}
      bodyText={blok.bodyText}
      headingText={blok.headingText}
      imageAlt={blok.image.alt}
      imageUrl={blok.image.filename}
      mobileImageAlt={blok.mobileImage?.alt}
      mobileImageUrl={blok.mobileImage?.filename}
      primaryButtonLink={
        blok.primaryCTALink && linkIsNotNull(blok.primaryCTALink)
          ? getUrlFromStoryblokLink(blok.primaryCTALink)
          : undefined
      }
      primaryButtonMobileLink={
        blok.primaryCTAMobileLink && linkIsNotNull(blok.primaryCTAMobileLink)
          ? getUrlFromStoryblokLink(blok.primaryCTAMobileLink)
          : ""
      }
      primaryButtonText={blok.primaryCTAText}
      trackingEvent={blok.trackingEvent}
      trackingEventKey={blok.trackingEventKey}
      trackingEventValue={blok.trackingEventValue}
      secondaryButtonLink={
        blok.secondaryCTALink && linkIsNotNull(blok.secondaryCTALink)
          ? getUrlFromStoryblokLink(blok.secondaryCTALink)
          : undefined
      }
      secondaryButtonText={blok.secondaryCTAText}
      videoUrl={
        blok.videoUrl?.cached_url || blok.videoUrl?.url
          ? getUrlFromStoryblokLink(blok.videoUrl)
          : ""
      }
      showCallout={blok.showCallout}
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    />
  )
}
